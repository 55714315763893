import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationsService } from '../notifications/notifications.service';
import { resolve } from 'url';


@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  public loader: any;
  public eventSource: any = {};
  public emergencies: any = [];
  constructor(
    public api: ApiService,
    public notification: NotificationsService
  ) {
    setInterval(() => {
      this.getAppointments();
    }, 60000);
    setInterval(() => {
      this.getEmergencyRequests();
    }, 5000);
  }
  getEmergencyRequests() {
    this.api.get('content/emergencies', false).then((resp) => {
      this.emergencies = resp.items;
    });
  }

  async sendJournalEntry(appointment, journalEntry: any) {
    const prom = this.api.post('rating/appointment/', { appointment_id: appointment.id, comment: journalEntry });
    prom.then((resp) => {
      this.api.helper.alert('OK!', 'Seu comentário foi salvo!');
    }).catch((e) => {
      this.api.helper.alert('Ops!', 'Ocorreu um erro!');
    });
    return prom;
  }
  getAppointments() {
    return new Promise((resolve, reject) => {
      this.eventSource = {}
      this.api.get('eventsAvailability/appointments', false).then((resp) => {
        for (let idx in resp.list) {
          let item = resp.list[idx];
          let eventItem = item;
          eventItem.start = item.datetime_start.split(' ')[1];
          eventItem.startAppointment = eventItem.start.split(':')[0] + ':' + eventItem.start.split(':')[1];
          eventItem.startTime = new Date(item.datetime_start.replace(' ', 'T'));
          var diffMs = (eventItem.startTime - (new Date()).getTime()); // milliseconds between now & Christmas
          if (diffMs > 0 && (diffMs / 1000 / 60) < 10) {
            console.log(diffMs)
            console.log(Math.round(((diffMs % 86400000) % 3600000) / 60000))
            this.notification.send('upcomingEvent', eventItem);
          }
          let end = item.datetime_end.split(' ')[1];
          eventItem.endAppointment = end.split(':')[0] + ':' + end.split(':')[1];
          eventItem.endTime = new Date(item.datetime_end.replace(' ', 'T'));
          eventItem.allDay = false;
          // console.log(item);
          // console.log(eventItem);
          if (!this.eventSource[item.datetime_start.split(' ')[0]]) {
            this.eventSource[item.datetime_start.split(' ')[0]] = [];
          }
          this.eventSource[item.datetime_start.split(' ')[0]].push(eventItem);
          // console.log(this.eventSource);
        }
        resolve(this.eventSource);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}
