import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public allowed: boolean = false;
  public queue: any = {};
  public queueCounter: number = 0;

  public notificationsModels: any = {
    upcomingEvent: {
      title: "{{event.startAppointment}} - {{event.name}}",
      text: "Você tem um agendamento com {{event.name}} às {{event.startAppointment}}",
      imgs: [
        // 'https://veryfunnypics.eu/wp-content/uploads/2017/11/funny-pictures-are-you-awake.jpg',
        // 'https://media.giphy.com/media/XufMtuY3DV5zG/giphy.gif',
        // 'https://media.giphy.com/media/pVkmGyqYRt4qY/giphy.gif',
      ]
    }
  };
  queueInterval: any = false;

  constructor(
  ) {
    if (!this.allowed) {
      Notification.requestPermission().then((result) => {
        console.log(result);
        if (result == 'granted') {
          this.allowed = true;
        } else {
          alert('Seu navegador bloqueou as notificações! Habilite de volta!');
        }
      });
    }
    if (!this.queueInterval) {
      this.queueInterval = setInterval(() => {
        this.queueCounter++;
        this.runQueue();
      }, 60000);
    }
  }

  runQueue() {
    for (let i in this.queue) {
      let item = this.queue[i];
      if (item.minutesInterval % this.queueCounter === 0) {
        this.send(i, item.user);
      }
    }
  }
  addToQueue(alias, user, minutesInterval) {
    this.queue[alias] = { user: user, minutesInterval: minutesInterval }
  }
  send(alias, event) {
    let notif = this.notificationsModels[alias];
    if (!notif) return false;

    let imgs = notif.imgs;
    let img = imgs[Math.floor(Math.random() * imgs.length)];

    let title = notif.title;
    let text = notif.text;

    text = text.replace('{{event.name}}', event.name);
    text = text.replace('{{event.startAppointment}}', event.startAppointment);
    title = title.replace('{{event.name}}', event.name);
    title = title.replace('{{event.startAppointment}}', event.startAppointment);

    let notification = new Notification(title, {
      body: text,
      icon: img,
      image: img,
      // requireInteraction: true // <<<<< Aparentemente os browsers não permitem mais notificações que ficam fixas
    });

  }

  validateSend(alias, user) {
    switch (alias) {
      case 'upcomingEvent':
        // Validação de envio
        break;
    }
    return true;
  }
}
